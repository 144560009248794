<template>
  <div>
    <div class="sctp-container sctp-pad-tb10 container clear">
      <div class="sctp-pad-b10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>商家中心</el-breadcrumb-item>
          <template v-for="item in currentRouter">
            <el-breadcrumb-item>{{ item }}</el-breadcrumb-item>
          </template>
        </el-breadcrumb>
      </div>
      <!--  左侧菜单  -->
      <div class="left-menu sctp-fl sctp-bg-white">
        <template v-for="menu in linkArray">
          <div :key="menu.name">
            <div class="title">
              <template v-if="menu.icon">
                <i :class="[menu.icon]"></i>
              </template>
              <h3>{{ menu.name }}</h3>
            </div>
            <ul>
              <template v-for="link in menu.children">
                <li :key="link.name">
                  <router-link :to="link.url" >{{ link.name }}</router-link>
                </li>
              </template>
            </ul>
          </div>
        </template>
      </div>
      <div class="right-content sctp-fl">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shopIndex',
  components: {},
  data() {
    return {
      linkArray: [
        {
          name: '店铺管理',
          url: '',
          icon: 'el-icon-s-shop',
          children: [
            {
              name: '我的店铺认证',
              url: '/shop/index'
            },
            {
              name: '店铺统计信息',
              url: '/shop/statistics'
            }
          ]
        }
      ],
      authLink: [
        {
          name: '原创出售管理',
          url: '',
          icon: 'el-icon-s-goods',
          children: [
            {
              name: '源码出售管理',
              url: '/shop/sale/sourcecode'
            },
            {
              name: '设计出售管理',
              url: '/shop/sale/design'
            },
            {
              name: '文档出售管理',
              url: '/shop/sale/techdocs'
            },
          ]
        },
        {
          name: '出售服务管理',
          url: '',
          icon: 'el-icon-s-promotion',
          children: [
            {
              name: '我出售的服务',
              url: '/shop/sale/service'
            }
          ]
        },
        {
          name: '出售交易管理',
          url: '',
          icon: 'el-icon-s-order',
          children: [
            {
              name: '源码设计文档交易订单',
              url: '/shop/transaction'
            },
            {
              name: '服务交易订单',
              url: '/shop/service-order'
            }
          ]
        }, {
          name: '业务管理',
          url: '',
          icon: 'el-icon-s-order',
          children: [
            {
              name: '意向客户管理',
              url: '/business/intentioncustomer'
            }
          ]
        }
      ]
    }
  },
  beforeMount() {
    this.queryIsSettleIn()
  },
  methods: {
    /**
     * 商户是否通过认证
     */
    queryIsSettleIn() {
      this.linkArray = this.linkArray.concat(this.authLink)
    }
  },
  computed: {
    currentRouter() {
      let routerPath = this.$route.path;
      let retArr = [];
      let {linkArray} = this;
      linkArray.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.children.forEach(route => {
            if (route.url === routerPath) {
              retArr.push(item.name);
              retArr.push(route.name);
            }
          })
        }
      })
      return retArr;
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  .left-menu {
    position: relative;
    width: 180px;
    margin-right: 10px;
    padding: 10px 0;

    .title {
      padding-left: 24px;
      cursor: pointer;
      line-height: 40px;
      display: flex;
      align-items: center;

      i {
        margin-right: 5px;
        width: 24px;
        text-align: center;
        font-size: 18px;
        color: #909399;
      }

      h3 {
        font-weight: bold;
      }
    }

    ul {
      li {
        border-left: 2px solid transparent;

        :hover {
          background-color: rgba(255, 153, 0, .2);
        }

        a {
          display: flex;
          height: 40px;
          align-items: center;
          padding-left: 24px;

          &.router-link-exact-active {
            background-color: $main-color;
            color: white !important;
          }
        }
      }

    }
  }

  .router-link-active {
    color: #F90;
  }

  .right-content {
    width: calc(100% - 190px);
  }
}

</style>
